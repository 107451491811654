<template>
  <div class="app-form">
    <el-form
      ref="formParcoursclinique"
      :model="form"
      label-width="100px"
      label-position="right"
      :rules="rules"
      :validate-on-rule-change="false"
    >
      <!-- CHAMPS -->
      <el-form-item label="Médecin" prop="id_med" v-show="!ismeddetail">
        <el-select
          v-model="form.id_med"
          clearable
          filterable
          placeholder="Choisissez un médecin"
        >
          <el-option
            v-for="item in medecinOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="activité" prop="id_activite">
        <el-select
          v-model="form.id_activite"
          clearable
          filterable
          placeholder="Choisissez une activité"
        >
          <el-option
            v-for="item in activiteOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="Taux" prop="id_taux">
        <el-select
          v-model="form.id_taux"
          clearable
          filterable
          placeholder="Choisissez un taux"
        >
          <el-option
            v-for="item in tauxOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="Début" prop="debut_date">
        <el-date-picker
          v-model="form.debut_date"
          value-format="yyyy-MM-dd"
          format="yyyy-MM-dd"
          type="date"
          placeholder="Format: aaaa-mm-jj"
          :clearable="false"
        ></el-date-picker>
      </el-form-item>
      <el-form-item label="Fin" prop="fin_date">
        <el-date-picker
          v-model="form.fin_date"
          value-format="yyyy-MM-dd"
          format="yyyy-MM-dd"
          type="date"
          placeholder="Format: aaaa-mm-jj"
          :clearable="false"
        ></el-date-picker>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
export default {
  data() {
    var valideDebut = (rule, value, callback) => {
      if (value) {
        callback();
        //this.$refs.formParcoursclinique.validateField('fin_date');
      } else {
        callback(new Error("Ce champ est obligatoire"));
      }
    };
    var valideFin = (rule, value, callback) => {
      if (value) {
        if (this.form.debut_date && this.form.debut_date >= value) {
          callback(new Error("la fin prévue doit être postérieure au début !"));
        } else {
          callback();
        }
      } else {
        //callback(new Error('Ce champ est obligatoire'));
        callback();
      }
    };
    return {
      rules: {
        id_med: [
          {
            required: true,
            message: "Ce champ est obligatoire",
            trigger: "change",
          },
        ],
        id_activite: [
          {
            required: true,
            message: "Ce champ est obligatoire",
            trigger: "change",
          },
        ],
        id_taux: [
          {
            required: true,
            message: "Ce champ est obligatoire",
            trigger: "change",
          },
        ],
        debut_date: [
          { required: true, validator: valideDebut, trigger: "change" },
        ],
        fin_date: [
          { required: false, validator: valideFin, trigger: "change" },
        ],
      },
    };
  },
  props: {
    ismeddetail: Boolean,
  },
  computed: {
    form: {
      // https://vuex.vuejs.org/guide/forms.html#two-way-computed-property
      get() {
        return this.$store.state.pc.parcoursclinique;
      },
      set(form) {
        this.$store.commit("pc/setParcoursclinique", form);
      },
    },
    medecinOptions() {
      return this.$store.getters["medl/medecinSelect"];
    },
    activiteOptions() {
      return this.$store.getters["pca/pcactiviteSelect"];
    },
    tauxOptions() {
      return this.$store.getters["tauxl/tauxSelect"];
    },
  },
};
</script>
