<template>
  <el-main class="rh-medform">
    <form-parcourscliniquecreate :routes="pcRoutes" :ismeddetail="true" />
  </el-main>
</template>
<script>
import FormParcourscliniquecreate from "@/components/rh/formulaires/FormParcourscliniquecreate";

export default {
  components: { "form-parcourscliniquecreate": FormParcourscliniquecreate },
  computed: {
    pcRoutes() {
      return {
        toedit: {
          name: "med_parcourscliniques_edit",
          params: {
            idmed: this.$route.params.idmed,
            idpc: this.$store.state.pc.parcourscliniqueLastCreatedId,
          },
        },
        tolist: {
          name: "med_parcourscliniques",
          params: {
            idmed: this.$route.params.idmed,
          },
        },
      };
    },
  },
};
</script>
